export const refreshAuthToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    console.error("No refresh token found.");
    window.location.assign("/login");
    return false;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH_URL}/refreshtoken`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ refreshToken }),
      }
    );

    const data = await response.json();

    if(!response.ok)
      throw new Error(data.message);
    if (response.ok) {
      const {
        st_access_token: newAuthToken,
        st_refresh_token: newRefreshToken,
      } = data.tokens;

      //console.log("ACCESS", newAuthToken, newRefreshToken);

      localStorage.setItem("authToken", newAuthToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      localStorage.setItem("generatedTime", Date.now());

      console.log("Auth token refreshed successfully.");
      return true;
    }
  } catch (error) {
    //console.error("Refresh token error:", error.message);
    throw new Error("Failed to refresh auth token");
  }
  return false;
};
