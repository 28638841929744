import React from "react";

const TermsConditions = () => {
  return (
    <div className="p-12">
      <h1 className="text-3xl text-darkgrey font-bold mb-4 text-center">
        Terms and Conditions
      </h1>
      <div className="space-y-4">
        <ol className="list-decimal flex flex-col gap-3">
          <li className="text-lg font-semibold">Definitions</li>
          <ul className="px-2 text-darkgrey list-disc text-sm">
            <li>
              <strong>Customer</strong> means the end user of the Service
              provided by TechStack through Reseller under an agreement between
              Reseller and Customer (the “Customer Agreement”).
            </li>
            <li>
              <strong>Customer Data</strong> means all electronic data or
              information submitted by Customer to the Service.
            </li>
            <li>
              <strong>Instance</strong> means a specific instance or URL of
              Customer’s Original Application.
            </li>
            <li>
              <strong>TechStack or Provider</strong> means TechStack Inc.
            </li>
            <li>
              <strong>Reseller</strong> means TechStack’s authorized reseller
              that is party to the Customer Agreement.
            </li>
            <li>
              <strong>Service</strong> means providing access to the System to
              Customer.
            </li>
            <li>
              <strong>System</strong> means TechStack’s online web-based user
              experience software provided under this Customer Agreement as an
              extension of the principal browser used by the Customer (Chrome,
              MS Edge, Chromium, Firefox, Safari) and/or a snippet code to be
              embedded in Customer’s Instance of the Original Application.
            </li>
            <li>
              <strong>Original Application</strong> means Customer’s hosted
              application the user interface of which the System transforms.
            </li>
            <li>
              <strong>Provider Data</strong> means the information collected or
              generated by Provider in the course of providing the System or any
              services under this Agreement.
            </li>
            <li>
              <strong>Users</strong> means Customer’s administrator-level
              employees, consultants, contractors, or agents who are authorized
              to use the Service and have been supplied User IDs, including
              passwords, by Provider.
            </li>
          </ul>
          <li className="text-base font-medium">
            TechStack is a third party beneficiary of this Customer Agreement.
          </li>
          <li className="text-base font-medium">
            TechStack will make the Services available to Customer subject to
            the terms of this Customer Agreement.
          </li>
          <li className="text-base font-medium">
            Customer shall be solely responsible, at its own expense, for
            acquiring, installing and maintaining all connectivity equipment,
            hardware, software and other equipment as may be necessary for its
            Users to connect to, access and use the Service except as otherwise
            provided by Provider. For example, Customer will be responsible for
            standard computer workstations and necessary network connections not
            provided by Provider. Customer acknowledges and agrees that Provider
            is not obligated to provide any anti-virus services, and Customer is
            responsible for ensuring that its environments are virus-free.
          </li>
          <li className="text-base font-medium">
            Each User will be assigned a unique User identification name and
            password for access to and use of the Service (“User ID”). Customer
            shall be responsible for ensuring the security and confidentiality
            of all User ID’s. The Service shall be used only by Users who
            possess and exercise administrative rights.
          </li>
          <li className="text-base font-medium">
            In addition to its confidentiality obligations under this Customer
            Agreement, Customer is responsible for all activities (whether
            lawful or unlawful) that occur while Customer personnel are using
            the System. Customer shall and shall ensure that its Users:  (a) use
            commercially reasonable efforts to prevent unauthorized access to,
            or use of, the Service, and notify Provider promptly of any such
            unauthorized use; (b) comply with all applicable local, state,
            federal laws in using the Service; and (c) inform Provider of
            material changes in its Instances of the Original Application.
            Customer hereby grants to Provider a perpetual, royalty-free, fully
            paid-up, irrevocable, transferable, sublicensable, worldwide license
            to use, copy, reproduce, display, alter, create derivative works of
            and otherwise modify Customer Data for Provider’s business purposes.
          </li>

          <li>
            Customer shall not:  (a) license, sublicense, sell, resell, rent,
            lease, transfer, assign, distribute, time share or otherwise
            commercially exploit or make the Service available to any third
            party, other than as contemplated by this Customer Agreement; (b)
            send to Provider or cause to be sent to Provider or store on any
            computer that is used to access the Service any infringing, obscene,
            threatening, libelous, or otherwise unlawful or tortuous material,
            including material harmful to children or violative of third party
            privacy rights; (c) send to Provider or cause to be sent to Provider
            or store on any computer that is used to access the Service material
            containing software viruses, worms, Trojan horses or other harmful
            computer code, files, scripts, agents or programs; (d) interfere
            with or disrupt the integrity or performance of the Service or the
            data contained therein; (e) attempt to gain unauthorized access to
            the Service or its related systems or networks; (f) conceal or
            remove any title, trademark, copyright, proprietary or restricted
            rights notice contained in the System or the Service associated
            therewith; and (g) send to Provider or cause to be sent to Provider
            or store in the Service personal or other sensitive information. 
          </li>
          <li>
            Customer hereby consents to Provider remotely running programs and
            other processes in Customer’s computing environment as part of the
            Services, including processes to monitor for changes to the Original
            Application.
          </li>
          <li>
            Provider reserves the right to temporarily suspend Customer’s access
            to the Service without notice for routine and emergency maintenance
            or if Provider becomes aware of or reasonably suspects Customer or
            Customer’s users are engaging in illegal, irresponsible or
            disruptive activities, including activities that may overburden or
            threaten the integrity, reliability, or function of the Service in
            any way.
          </li>
          <li>
            Customer acknowledges that in providing the Service, Provider
            utilizes (a) the Provider name, the Provider logo, the Provider
            domain name, the product and service names associated with the
            Service, and other trademarks and service marks; (b) certain audio
            and visual information, documents, software and other works of
            authorship; and (c) other technology, software, hardware, products,
            processes, algorithms, user interfaces, know-how and other trade
            secrets, techniques, designs, inventions and other tangible or
            intangible technical material or information (collectively,
            “Provider Technology”) and that Provider owns or licenses patent
            rights, trademark rights, copyrights and other intellectual property
            rights to the Provider Technology (collectively, “Provider IP
            Rights”). Other than as expressly set forth in this Customer
            Agreement, Provider does not grant or otherwise convey any license
            or other right in or to the Provider Technology or Provider IP
            Rights to Customer. Provider expressly reserves all rights to the
            Provider Technology not expressly granted under this Customer
            Agreement. There are no implied licenses under this Customer
            Agreement. Customer acknowledges and agrees that any breach of this
            Customer Agreement by its User or any other employee, agent or
            contractor of Customer shall be deemed a breach of this Customer
            Agreement by Customer.
          </li>
          <li>
            Customer shall not (a) reproduce, copy, alter, adapt, modify,
            improve, enhance, translate, or create derivative works based on the
            Service or any other Provider Technology; (b) create  “links” to or
            from the Service, or “frame” or “mirror” any content forming part of
            the Service, other than on Customer’s own intranet(s) for its own
            internal business purposes; or (c) disassemble, reverse engineer,
            decompile, or otherwise attempt to reveal the code, trade secrets or
            know-how underlying the Service or any other Provider Technology for
            any reason, or access the Service in order to (i) build a
            competitive product or service, (ii) build a product or service
            using similar features, functions or graphics of the Service, or
            (iii) copy any features, functions or graphics of the Service.
          </li>
          <li>
            Customer shall not (a) reproduce, copy, alter, adapt, modify,
            improve, enhance, translate, or create derivative works based on the
            Service or any other Provider Technology; (b) create  “links” to or
            from the Service, or “frame” or “mirror” any content forming part of
            the Service, other than on Customer’s own intranet(s) for its own
            internal business purposes; or (c) disassemble, reverse engineer,
            decompile, or otherwise attempt to reveal the code, trade secrets or
            know-how underlying the Service or any other Provider Technology for
            any reason, or access the Service in order to (i) build a
            competitive product or service, (ii) build a product or service
            using similar features, functions or graphics of the Service, or
            (iii) copy any features, functions or graphics of the Service.
          </li>
          <li>
            Customer hereby grants Provider the right to use the name and
            service marks of Customer in its marketing materials or other oral,
            electronic, or written promotions, which shall include naming
            Customer as a client of Provider and a brief scope of services
            provided. Any and all such marketing materials must be approved by
            both the Customer and Provider prior to any public display or
            distribution. In addition, Customer hereby grants Provider the right
            to display its logo (or other trademark or other identifying
            information) and a hyperlink to Provider’s website on the home page
            of Customer’s website. Any use of Provider’s logos or links on
            Customers website must be approved in writing by Provider. 
          </li>
          <li>
            Under no circumstances will Provider be liable for any damage or
            loss resulting from a cause over which Provider does not have
            control, including, without limitation, failure of electronic or
            mechanical equipment or communications lines, network connectivity,
            telephone or other interconnect problems, unauthorized access, theft
            or operator error.  
          </li>
          <li>
            EXCEPT AS EXPRESSLY PROVIDED HEREIN, PROVIDER MAKES NO WARRANTY OF
            ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE RELATING
            TO THE USE, FUNCTIONALITY, OR AVAILABILITY OF THE SERVICE, AND
            Provider HEREBY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES,
            INCLUDING ANY WARRANTY OF NON-INFRINGEMENT, MERCHANTABILITY OR
            FITNESS FOR A PARTICULAR PURPOSE, TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW. PROVIDER DOES NOT WARRANT THAT THE SERVICE WILL BE
            PROVIDED ERROR-FREE, UNINTERRUPTED, SECURE OR VIRUS-FREE. WITHOUT
            LIMITING THE FOREGOING, PROVIDER MAKES NO REPRESENTATION OR
            WARRANTIES OF ANY KIND WITH RESPECT TO THE THIRD PARTY COMPONENTS OF
            THE SERVICE. None of Provider, its suppliers or its licensors will
            have any liability whatsoever for (a) the accuracy, completeness or
            timeliness of the rendering or organization of the Original
            Application provided through the Service, (b) any decision made or
            action taken by Customer in reliance upon such rendering or
            organization of the Original Application (c) any functions,
            malfunctions, defects, features, processes, errors, vulnerabilities,
            or bugs in the Original Application or the consequences thereof; (d)
            Customer’s interactions with, and other activities related to, the
            Original Application, through the Services or otherwise; or (e) any
            other information obtained by Customer from the Original Application
            or any other third party application through the Service. The
            parties agree and acknowledge that Provider shall in no event be
            held responsible for any problems with the Service attributable to
            the public
          </li>
          <li>
            THE ENTIRE LIABILITY OF PROVIDER AND ITS EMPLOYEES, DIRECTORS,
            INVESTORS, SUBSIDIARIES, AFFILIATES, LICENSORS, THIRD-PARTY CONTENT
            OR SERVICE PROVIDERS, DISTRIBUTORS, DEALERS OR SUPPLIERS
            (COLLECTIVELY, THE “REPRESENTATIVES”) FOR ANY REASON SHALL BE
            LIMITED TO AN AMOUNT EQUAL TO THE FEES PAID BY CUSTOMER TO RESELLER
            HEREUNDER DURING THE TWELVE MONTH PERIOD IMMEDIATELY PRECEDING THE
            EVENT GIVING RISE TO LIABILITY. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, PROVIDER AND ITS REPRESENTATIVES ARE NOT LIABLE FOR
            ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
            (INCLUDING, BUT NOT LIMITED TO DAMAGES FOR LOSS OF BUSINESS, LOSS OF
            PROFITS OR INVESTMENT, OR THE LIKE) ARISING UNDER THIS CUSTOMER
            AGREEMENT, OR RELATED TO THE SERVICE, WHETHER BASED ON BREACH OF
            CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT
            LIABILITY OR OTHERWISE, EVEN IF PROVIDER OR ITS REPRESENTATIVES HAVE
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A
            REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
            PURPOSE. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
            ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN Provider AND Customer.
            No action (regardless of form) arising out of this Customer
            Agreement may be commenced by Customer more than two years after the
            cause of action has occurred no matter when discovered.  
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsConditions;
