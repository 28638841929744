import React from 'react'
import Layout from '../../utils/Layout'
import { CiSearch } from 'react-icons/ci'
import menu from "../../assets/misc/hamburger.png";

const Application = () => {
  return (
    <Layout>
    <div className="p-3 flex justify-between">
      <div>
        <h2 className="text-2xl font-semibold mb-3">My Applictions</h2>
        <span className="text-darkgrey">
        Check all the apps you have purchased.
        </span>
      </div>
      <div className="flex items-center gap-3 relative">
        <div className="relative bg-white">
          <CiSearch
            className="absolute top-1/2 left-3 transform -translate-y-1/2 text-lightgrey"
            size={24}
          />
          <input
            type="text"
            placeholder="Search"
            className="pl-10 pr-4 py-2 outline-none border-none bg-transparent w-full"
          />
        </div>
        <button className="bg-white px-2 py-1 ">
          <img src={menu} alt="hamburger menu" />
        </button>

        <button className="bg-white px-2 py-1 text-darkgrey">
          Clubbed Apps
        </button>
      </div>
    </div>
  </Layout>

  )
}

export default Application