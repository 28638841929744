import React from "react";
import { FcGoogle } from "react-icons/fc";
import { useLocation } from "react-router-dom";
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdparty";

const GoogleAuthButton = () => {
  const location = useLocation();
  const isLoginPage = location.pathname;
  async function googleSignInClicked() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: "google",

        frontendRedirectURI: `${process.env.REACT_APP_BASE_URL}/auth/callback/google`,
      });
      //console.log("auth url = ", authUrl);
      window.location.assign(authUrl);
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }

  return (
    <div>
      <button
        className="flex items-center bg-white text-black rounded-md px-4 py-2 my-6 shadow-md"
        onClick={googleSignInClicked}
      >
        <FcGoogle className="mr-2" size={20} />
        Sign {isLoginPage === "/login" ? "in" : "up"} with Google
      </button>
    </div>
  );
};

export default GoogleAuthButton;
