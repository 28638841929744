import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import menu from "../../assets/misc/hamburger.png";
import MarketplaceCard from "../../components/MarketPlaceCard";
import Layout from "../../utils/Layout";

const Marketplace = () => {
  const marketplaceData = [
    {
      id: "1",
      name: "Generate Leads in LISN and Outreach in Dripify",
      imageUrl: "https://picsum.photos/600/500",
      isFavorite: true,
    },
    {
      id: "2",
      name: "Lorem Ipsum",
      imageUrl: "https://picsum.photos/600/500",
      isFavorite: false,
    },
    {
      id: "3",
      name: "Lorem Ipsum",
      imageUrl: "https://picsum.photos/600/500",
      isFavorite: false,
    },
  ];
  const [items, setItems] = useState(marketplaceData);

  const toggleFavorite = (id) => {
    const updatedData = items.map((item) => {
      if (item.id === id) {
        return { ...item, isFavorite: !item.isFavorite };
      }
      return item;
    });
    setItems(updatedData);
  };

  return (
    <Layout>
      <div className="p-3 flex justify-between">
        <div>
          <h2 className="text-2xl font-semibold mb-3">Marketplace</h2>
          <span className="text-darkgrey">
            Check all the services we are providing.
          </span>
        </div>
        <div className="flex items-center gap-3 relative">
          <div className="relative bg-white">
            <CiSearch
              className="absolute top-1/2 left-3 transform -translate-y-1/2 text-lightgrey"
              size={24}
            />
            <input
              type="text"
              placeholder="Search"
              className="pl-10 pr-4 py-2 outline-none border-none bg-transparent w-full"
            />
          </div>
          <button className="bg-white px-2 py-1 ">
            <img src={menu} alt="hamburger menu" />
          </button>

          <button className="bg-white px-2 py-1 text-darkgrey">
            Clubbed Apps
          </button>
        </div>
      </div>

      <div className="px-3 py-4">
        <p className="text-sm text-gray-400 mb-2">Favorite</p>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5">
          {items
            .filter((item) => item.isFavorite)
            .map((item, index) => (
              <MarketplaceCard
                item={item}
                key={index}
                toggleFavorite={toggleFavorite}
              />
            ))}
        </div>
      </div>

      <div className="px-3 py-4">
        <p className="text-sm text-gray-400 mb-2">New Stacks</p>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5">
          {items
            .filter((item) => !item.isFavorite)
            .map((item, index) => (
              <MarketplaceCard
                item={item}
                key={index}
                toggleFavorite={toggleFavorite}
              />
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default Marketplace;
