import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdParty, { Google } from "supertokens-auth-react/recipe/thirdparty";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";

SuperTokens.init({
  appInfo: {
    appName: "TechStack webapp",
    apiDomain: process.env.REACT_APP_BACKEND_BASE,
    websiteDomain: process.env.REACT_APP_BASE_URL,
    apiBasePath: "/auth",
    websiteBasePath: "/",
  },
  recipeList: [
    EmailPassword.init({}),
    ThirdParty.init({
      signInAndUpFeature: {
        providers: [Google.init()],
      },
    }),
    EmailVerification.init(),
    Session.init({
      exposeAccessTokenToFrontendInCookieBasedAuth: true,
    }),
  ],
});
