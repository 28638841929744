import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoStarOutline, IoStarSharp } from "react-icons/io5";
import menu from "../../assets/misc/hamburger.png";
import MarketplaceDetailsTable from "../../components/MarketplaceDetailsTable";
import Layout from "../../utils/Layout";

const MarketplaceDetails = () => {
  const [toggleFeatures, setToggleFeatures] = useState(true);
  const [selectOption, setSelectOption] = useState("DIY");
  const [isMonthly, setIsMonthly] = useState(true);
  const marketplaceItemDetails = {
    id: "1",
    name: "Generate Leads in LISN and Outreach in Dripify",
    imageUrl: "https://picsum.photos/600/500",
    isFavorite: true,
    plan_options: ["DIY", "DIVM", "DIFM", "Add On"],
    categories: [
      {
        featureTitle: "Setup and Configuration",
        features: [
          {
            title: "Workflow Designs",
            options: ["DIY", "DIVM", "DIFM"],
          },
          {
            title: "Initial setup of selected Saas tools",
            options: ["DIY", "DIVM", "DIFM", "Add On"],
          },
          {
            title: "Basic Configuration of SaaS tools & 1 campaign Integration",
            options: ["DIY", "DIVM", "DIFM"],
          },
          {
            title: "Tech Infrastructure Lease",
            options: ["DIY", "DIVM", "DIFM", "Add On"],
          },
        ],
      },
      {
        featureTitle: "Reporting and Insights",
        features: [
          {
            title: "Basic Reporting",
            options: ["DIY", "DIWM", "DIFM", "Add On"],
          },
          {
            title: "Basic Insights",
            options: ["DIY", "DIWM", "DIFM"],
          },
          {
            title: "Continuous performance monitoring",
            options: ["DIY", "DIFM"],
          },
          {
            title: "Weekly and monthly reports with insights",
            options: ["DIY", "DIWM", "DIFM", "Add On"],
          },
        ],
      },
    ],
  };

  return (
    <Layout>
      <div className="p-3 flex justify-between">
        <div>
          <h2 className="text-2xl font-semibold mb-3">Marketplace</h2>
          <span className="text-darkgrey">
            Check all the services we are providing.
          </span>
        </div>
        <div className="flex items-center gap-3 relative">
          <div className="relative bg-white">
            <CiSearch
              className="absolute top-1/2 left-3 transform -translate-y-1/2 text-lightgrey"
              size={24}
            />
            <input
              type="text"
              placeholder="Search"
              className="pl-10 pr-4 py-2 outline-none border-none bg-transparent w-full"
            />
          </div>
          <button className="bg-white px-2 py-1 ">
            <img src={menu} alt="hamburger menu" />
          </button>

          <button className="bg-white px-2 py-1 text-darkgrey">
            Clubbed Apps
          </button>
        </div>
      </div>

      <div className="w-full rounded-lg bg-white p-4">
        <div className="flex gap-4">
          <div className="w-80">
            <div className="flex items-start gap-2">
              {marketplaceItemDetails.isFavorite ? (
                <IoStarSharp
                  size={20}
                  color="#FFB802"
                  className="cursor-pointer"
                />
              ) : (
                <IoStarOutline size={20} className="cursor-pointer" />
              )}

              <img
                src={marketplaceItemDetails.imageUrl}
                alt="imageUrl"
                height={140}
                width={160}
                className="rounded-lg"
              />
            </div>
            <div>
              <h2 className="text-lg font-semibold my-3 underline">
                {marketplaceItemDetails.name}
              </h2>
              <button
                className="flex items-center justify-center gap-3 rounded-xl border border-blue w-32 py-1"
                onClick={() => setToggleFeatures(!toggleFeatures)}
              >
                Details{" "}
                {toggleFeatures ? (
                  <IoIosArrowUp size={18} />
                ) : (
                  <IoIosArrowDown size={18} />
                )}
              </button>
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-start justify-between gap-2">
              <div className="font-semibold">Plan Options</div>
              <div className="flex items-center gap-2">
                <div>Anually</div>
                <div
                  className={`relative flex items-center cursor-pointer rounded-full w-10 h-5 ${
                    isMonthly ? "bg-blue" : "bg-blue"
                  }`}
                  onClick={() => setIsMonthly(!isMonthly)}
                >
                  <div
                    className={`absolute -top-0.5 -left-1 w-6 h-6 bg-blue rounded-full shadow-md transform ${
                      isMonthly ? "translate-x-6" : "translate-x-0"
                    } transition-transform duration-300`}
                  />
                </div>
                <div>Monthly</div>
              </div>
            </div>
            <hr height={1} className="mt-2" color="gray" />
            <div className="flex gap-4">
              {marketplaceItemDetails.plan_options.map((option) => (
                <div
                  key={option}
                  className={`${
                    option === selectOption
                      ? "shadow-[0px_0px_10px_6px_rgba(4,172,251,0.7)] border border-blue"
                      : ""
                  } rounded-full bg-[#D9D9D9] mt-10 w-14 h-14 flex items-center justify-center text-xs font-semibold text-center hover:cursor-pointer`}
                  onClick={() => setSelectOption(option)}
                >
                  <div>{option}</div>
                </div>
              ))}
            </div>
            <div className="mt-4">select one option to continue</div>
          </div>
        </div>

        {toggleFeatures && (
          <MarketplaceDetailsTable itemDetails={marketplaceItemDetails} />
        )}
      </div>
    </Layout>
  );
};

export default MarketplaceDetails;
