import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { SuperTokensWrapper } from "supertokens-auth-react";
import "./App.css";
import "./SuperTokensConfig";

import Forgotpassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import NewPassword from "./pages/NewPassword";
import OnboardingPage from "./pages/OnboardingPage";
import Signup from "./pages/Signup";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import SignupSuccess from "./pages/SignupSuccess";
import TermsConditions from "./pages/TermsConditions";

import GoogleCallback from "./utils/GoogleCallback";
import RedirectIfAuthenticated from "./utils/RedirectedIfAuth";
import SessionAuth from "./utils/SessionAuth";

import Application from "./pages/protected/Application";
import Dashboard from "./pages/protected/Dashboard";
import Invoice from "./pages/protected/Invoice";
import Marketplace from "./pages/protected/Marketplace";
import Subscriptions from "./pages/protected/Subscriptions";
import MarketplaceDetails from "./pages/protected/MarketplaceDetails";

function App() {
  return (
    <SuperTokensWrapper>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <SessionAuth>
                <Dashboard />
              </SessionAuth>
            }
          />
          <Route
            path="/onboard"
            element={
              <SessionAuth>
                <OnboardingPage />
              </SessionAuth>
            }
          />
          <Route
            path="/signin"
            element={
              <RedirectIfAuthenticated>
                <Signup />
              </RedirectIfAuthenticated>
            }
          />
          <Route
            path="/login"
            element={
              <RedirectIfAuthenticated>
                <Login />
              </RedirectIfAuthenticated>
            }
          />

          <Route
            path="/auth/reset-password"
            element={
              <SessionAuth>
                <NewPassword />
              </SessionAuth>
            }
          />
          <Route
            path="/signup_success"
            element={
              <SessionAuth>
                <SignupSuccess />
              </SessionAuth>
            }
          />
          <Route path="/resetpassword" element={<Forgotpassword />} />
          {/* <Route path="/verify-email" element={<VerifyEmail />} /> */}
          <Route path="/auth/callback/google" element={<GoogleCallback />} />
          <Route path="/privacy_policies" element={<PrivacyPolicy />} />
          <Route path="/terms_and_conditions" element={<TermsConditions />} />

          {/* routes for the dashboard */}
          <Route
            path="/invoices"
            element={
              <SessionAuth>
                <Invoice />
              </SessionAuth>
            }
          />
          <Route
            path="/subscription"
            element={
              <SessionAuth>
                <Subscriptions />
              </SessionAuth>
            }
          />
          <Route
            path="/marketplace"
            element={
              <SessionAuth>
                <Marketplace />
              </SessionAuth>
            }
          />
          <Route
            path="/marketplace/:id"
            element={
              <SessionAuth>
                <MarketplaceDetails />
              </SessionAuth>
            }
          />
          <Route
            path="/application"
            element={
              <SessionAuth>
                <Application />
              </SessionAuth>
            }
          />
        </Routes>
      </Router>
    </SuperTokensWrapper>
  );
}

export default App;
