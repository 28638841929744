import React, { useState } from "react";
import logo from "../assets/logo.png";
import { PiBell } from "react-icons/pi";
import Logout from "./Logout";

const Navbar = ({ profilePic }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    console.log("Logout");
  };

  return (
    <div className="bg-white my-6 mx-6 p-3 rounded-full flex justify-between items-center relative">
      <div className="w-[180px]">
        <img src={logo} alt="logo" />
      </div>
      <div className="flex items-center gap-3">
        <div className="bg-beige p-3 rounded-full">
          <PiBell size={24} />
        </div>
        <div className="relative">
          <div className="w-12 h-12 rounded-full cursor-pointer" onClick={toggleDropdown}>
            <img src={profilePic} alt="profile" className="rounded-full" />
          </div>
          {showDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-blue text-blue rounded shadow-lg z-50">
             <Logout />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
