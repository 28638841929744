import React, { useState } from "react";
import Layout from "../../utils/Layout";
import SubscriptionCard from "../../components/SubscriptionCard";

const Subscriptions = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const plans = [
    {
      planName: "Starter Plan",
      price: "11",
      visitors: "20,000",
      features: [
        "Create Unlimited Widgets",
        "CMS Integration",
        "All Widget Types",
      ],
      isHighlighted: false,
    },
    {
      planName: "Starter Plan",
      price: "36",
      visitors: "20,000",
      features: [
        "Create Unlimited Widgets",
        "CMS Integration",
        "All Widget Types",
      ],
      isHighlighted: false,
    },
    {
      planName: "Starter Plan",
      price: "36",
      visitors: "50,000",
      features: [
        "Create Unlimited Widgets",
        "CMS Integration",
        "All Widget Types",
      ],
      isHighlighted: true,
    },
  ];

  return (
    <Layout>
      <div className="text-center xl:p-8">
        <h2 className="text-2xl font-semibold mb-2">Ready to get started?</h2>
        <span className="text-darkgrey">Select you plan</span>
        <div className="flex items-center justify-center gap-3 text-xl font-medium my-2">
          <span>Monthly</span>
          <div
            className={`relative flex items-center cursor-pointer rounded-full w-16 h-8 ${
              isMonthly ? "bg-blue" : "bg-blue"
            }`}
            onClick={() => setIsMonthly(!isMonthly)}
          >
            <div
              className={`absolute top-1 left-1 w-6 h-6 bg-white rounded-full shadow-md transform ${
                isMonthly ? "translate-x-8" : "translate-x-0"
              } transition-transform duration-300`}
            />
          </div>
          <span>Yearly</span>
        </div>
      </div>
      <div className="flex justify-center gap-8 my-4">
        {plans.map((plan, index) => (
          <SubscriptionCard key={index} {...plan} />
        ))}
      </div>
    </Layout>
  );
};

export default Subscriptions;
