import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

const OnboardingPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    companyFind: "",
    useProduct: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const { firstName, lastName, phone } = formData;
    if (!firstName || !lastName || !phone) {
      setError("All fields are required");
      return;
    }
    try {
      const authToken = localStorage.getItem("authToken");
      //console.log(authToken)
      const response = await fetch(
        `${process.env.REACT_APP_AUTH_URL}/onboard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          credentials: "include",
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Onboarding successful:", data);
        localStorage.setItem("isAuth", true);
        navigate("/signup_success");
      } else {
        const data = await response.json();
        setError(data.message || "Onboarding failed");
      }
    } catch (err) {
      setError("An error occurred during onboarding");
    }
  };

  return (
    <div className="min-h-screen bg-beige overflow-hidden">
      {/* onboarding navbar */}
      <div className="w-full">
        <div className="bg-angular-gradient flex justify-center m-6 rounded-full">
          <div className="p-2 w-[150px]  rounded-full">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="mt-24 flex flex-col justify-center items-center">
          <h2 className="text-2xl text-blue font-semibold">
            Get Started with TechStack
          </h2>
          <div className="p-6 rounded w-full max-w-lg">
            <form onSubmit={handleSubmit}>
              {error && <div className="text-red mb-4">{error}</div>}
              <div className="flex gap-3 justify-between text-sm text-darkgrey">
                <div className="flex-1 mb-4">
                  <label className="block mb-2" htmlFor="firstName">
                    First Name <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="w-full p-2 border border-input-border rounded-md outline-none"
                    required
                  />
                </div>

                <div className="flex-1 mb-4">
                  <label className="block mb-2" htmlFor="lastName">
                    Last Name <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="w-full p-2 border border-input-border rounded-md outline-none"
                    required
                  />
                </div>
              </div>

              <div className="mb-4 text-sm text-darkgrey">
                <label className="block mb-2" htmlFor="phone">
                  Phone Number <span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-2 border border-input-border rounded-md outline-none"
                  placeholder="+91"
                  required
                />
              </div>
              <div className="mb-4 text-sm text-darkgrey">
                <label className="block mb-2" htmlFor="companyFind">
                  How did you find this company?
                </label>
                <select
                  id="companyFind"
                  value={formData.companyFind}
                  onChange={handleChange}
                  className="w-full p-2 border border-input-border rounded-md outline-none"
                >
                  <option value="">Select an option</option>
                  <option value="social_media">Social Media</option>
                  <option value="friend">Friend</option>
                  <option value="advertisement">Advertisement</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="mb-4 text-sm text-darkgrey">
                <label className="block mb-2" htmlFor="useProduct">
                  What is your plan to use our services?
                </label>
                <select
                  id="useProduct"
                  value={formData.useProduct}
                  onChange={handleChange}
                  className="w-full p-2 border border-input-border rounded-md outline-none "
                >
                  <option value="">Select an option</option>
                  <option value="personal">Personal</option>
                  <option value="business">Business</option>
                  <option value="education">Education</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <button
                type="submit"
                className="w-full bg-blue text-white p-2 rounded-full"
              >
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
