import React from "react";
import { IoStarOutline, IoStarSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const MarketplaceCard = ({ item, toggleFavorite }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col p-4 items-center bg-white rounded-lg w-48">
      <img
        src={item.imageUrl}
        alt="imageUrl"
        height={140}
        width={160}
        className="rounded-lg"
      />
      <div className="flex items-start justify-between w-full py-2">
        <div className="font-semibold text-sm w-32">{item.name}</div>
        {item.isFavorite ? (
          <IoStarSharp
            size={20}
            color="#FFB802"
            onClick={() => toggleFavorite(item.id)}
            className="cursor-pointer"
          />
        ) : (
          <IoStarOutline
            size={20}
            className="cursor-pointer"
            onClick={() => toggleFavorite(item.id)}
          />
        )}
      </div>
      <button
        className="rounded-xl border border-blue w-full py-1"
        onClick={() => {
          navigate(`/marketplace/${item.id}`);
        }}
      >
        Details
      </button>
    </div>
  );
};

export default MarketplaceCard;
