import React from "react";
import { Link } from "react-router-dom";
import { FaThumbsUp } from "react-icons/fa6";
import confetti from "../assets/confetti.png";

const SignupSuccess = () => {
  return (
    <div className="min-h-screen bg-beige flex items-center justify-center">
      <div className="bg-white px-32 py-24 flex flex-col items-center">
        <div className="relative flex flex-col items-center justify-center">
          <img src={confetti} alt="signup successful" />
          <div className="bg-beige rounded-full p-4 flex justify-center items-center w-40 h-40 ">
            <FaThumbsUp size={64} className="text-[#605BFF]" />
          </div>
        </div>
        <p className="my-8">Your account was successfully created.</p>
        <Link to="/" className="w-full bg-blue text-white p-2 rounded-full text-center">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default SignupSuccess;
