import React, { useEffect, useMemo, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineMessage } from "react-icons/ai";
import { CiSettings } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {  RiAppsLine } from "react-icons/ri";
import { MdOutlineDashboard } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";


const DashboardSidebar = ({ firstname }) => {
  const [billingOpen, setBillingOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const dashboard = useMemo(
    () => [
      {
        name: "Dashboard",
        icon: <MdOutlineDashboard size={20} />,
        link: "/",
        subCat: false,
      },
      {
        name: "Billings",
        icon: <AiOutlineMessage size={20} />,
        link: null,
        subCat: true,
        subCategories: [
          {
            name: "Subscriptions",
            link: "/subscription",
          },
          {
            name: "Invoicing",
            link: "/invoices",
          },
        ],
      },
      {
        name: "Marketplace",
        icon: <RxDashboard size={20} />,
        link: "/marketplace",
        subCat: false,
      },
      {
        name: "Application",
        icon: <RiAppsLine size={20} />,
        link: "/application",
        subCat: false,
      },
      {
        name: "Settings",
        icon: <CiSettings size={24} />,
        link: "/",
        subCat: false,
      },
    ],
    []
  );

  useEffect(() => {
    const currentTab = dashboard.find(
      (item) => item.link === location.pathname
    );
    if (currentTab) {
      setActiveTab(currentTab.name);
    }
  }, [location.pathname, dashboard]);

  const handleTabClick = (name, hasSubCat, link) => {
    setActiveTab(name);
    if (hasSubCat) {
      setBillingOpen(!billingOpen);
    }
    if (link) {
      navigate(link);
    }
  };

  const getCurrentDate = () => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const today = new Date();
    return today.toLocaleDateString("en-US", options);
  };

  return (
    <div className="h-full bg-white p-4 xl:p-6 rounded-3xl">
      <h3 className="font-sm text-2xl lg:text-3xl mb-2">
        Welcome Back, <br />
        <span className="font-medium">{firstname}</span>
      </h3>
      <span className="text-darkgrey">{getCurrentDate()}</span>

      <div className="bg-beige my-4 rounded-lg text-sm lg:text-lg">
        <ul>
          {dashboard.map((item, index) => (
            <li
              key={index}
              className={`flex flex-col p-3 rounded-md hover:text-darkgrey ${
                activeTab === item.name
                  ? "border-l-4 border-l-blue text-darkgrey"
                  : "text-mediumgrey"
              }`}
            >
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() =>
                  handleTabClick(item.name, item.subCat, item.link)
                }
              >
                <div className="flex items-center">
                  {item.icon}
                  <span className="ml-2">{item.name}</span>
                </div>
                {item.subCat && (
                  <span className="ml-auto">
                    {billingOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                )}
              </div>
              {item.subCat && billingOpen && (
                <div className="relative">
                  <div className="absolute left-[5px] top-0 bottom-0 w-[2px] bg-lightgrey"></div>
                  <ul>
                    {item.subCategories.map((category, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={category.link}
                          className="flex items-center mb-1 text-lightgrey hover:text-darkgrey"
                        >
                          <div className="relative w-4 h-full">
                            <div className="absolute top-0 left-[5px] w-4 h-4 border-l-2 border-b-2 border-lightgrey rounded-bl-full"></div>
                          </div>
                          <div className="ml-3 mt-2">{category.name}</div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;
