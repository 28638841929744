import React from "react";
import { FaCheck } from "react-icons/fa6";

const SubscriptionCard = ({
  planName,
  price,
  visitors,
  features,
  isHighlighted,
}) => {
  return (
    <div
      className={`py-12 px-6 text-center rounded-lg shadow-lg ${
        isHighlighted ? "bg-blue text-white" : "bg-white text-gray-800"
      } transform transition-transform hover:scale-105`}
    >
      <h3 className="text-2xl font-medium mb-4">{planName}</h3>
      <div className="flex items-baseline justify-center mb-12">
        <span className="text-5xl font-medium">${price}</span>
        <span className="text-xl ml-1">/month</span>
      </div>
      <ul className="mb-6 space-y-6 text-sm font-medium text-dark-grey">
        <li className="flex items-center">
          <FaCheck
            size={18}
            className={`mr-4 ${isHighlighted ? "text-white" : "text-blue "}`}
          />
          {visitors} Visitors
        </li>
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <FaCheck
              size={18}
              className={`mr-4 ${isHighlighted ? "text-white" : "text-blue "}`}
            />
            {feature}
          </li>
        ))}
      </ul>
      <button
        className={`w-full py-3 px-4 rounded-lg my-3  ${
          isHighlighted
            ? "bg-white text-blue"
            : "bg-blue hover:bg-blue text-white"
        }`}
      >
        Create a free account
      </button>
    </div>
  );
};

export default SubscriptionCard;
