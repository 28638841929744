import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import UpdateProfile from "./UpdateProfile";
import { FaEnvelope } from "react-icons/fa6";
import { Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";

const ProfileCard = ({ profilePic, firstname, lastName, email, phone }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: firstname || "",
    lastName: lastName || "",
    phone: phone || "",
    state: "",
    country: "",
    language: "",
    timezone: "",
  });

  useEffect(() => {
    setFormData({
      firstName: firstname || "",
      lastName: lastName || "",
      phone: phone || "",
      state: "",
      country: "",
      language: "",
      timezone: "",
    });
  }, [firstname, lastName, phone]);

  const handleUpdateClick = () => {
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const fields = [
    { id: "phone", label: "Phone Number", prefix: "+91" },
    { id: "state", label: "State" },
    { id: "country", label: "Country" },
    { id: "language", label: "Language" },
    { id: "timezone", label: "Time Zone" },
  ];

  return (
    <div className="lg:mx-3">
      <div className="bg-angular-gradient p-4 flex justify-center rounded-t-lg">
        <div className="p-2 w-[150px]">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="min-h-screen p-3 lg:p-12 bg-white rounded-b-lg flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-6">
              <div className="w-16 h-16 lg:w-24 lg:h-24">
                <img src={profilePic} alt="profile" className="rounded-full" />
              </div>
              <div>
                <h2 className="text-xl lg:text-3xl font-base">
                  {firstname + " " + lastName}
                </h2>
                <span className="text-sm lg:text-lg text-darkgrey">{email}</span>
              </div>
            </div>
            <div>
              <UpdateProfile
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                handleUpdateClick={handleUpdateClick}
                formData={formData}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 text-sm lg:text-lg">
            <div className="flex flex-col gap-3 col-span-1">
              {isEditing ? (
                <div className="flex flex-col gap-3 p-3">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="p-4 bg-lightblue rounded-lg text-darkgrey shadow-inner shadow-[inset_5px_5px_10px_rgba(0,0,0,0.15),_inset_-5px_-5px_10px_rgba(255,255,255,0.1)]"
                  />

                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="p-4 bg-lightblue rounded-lg text-darkgrey shadow-inner shadow-[inset_5px_5px_10px_rgba(0,0,0,0.15),_inset_-5px_-5px_10px_rgba(255,255,255,0.1)]"
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-3 p-3">
                  <label>Full Name</label>
                  <input
                    type="text"
                    value={firstname + " " + lastName}
                    disabled
                    className="p-4 text-lightgrey"
                  />
                </div>
              )}
            </div>
            {fields.map((field) => (
              <div key={field.id} className="flex flex-col gap-3 col-span-1  text-sm lg:text-lg">
                {isEditing ? (
                  <div className="flex flex-col gap-3 p-3">
                    <label htmlFor={field.id}>{field.label}</label>
                    <input
                      type="text"
                      id={field.id}
                      value={formData[field.id]}
                      onChange={handleChange}
                      className="p-4 bg-lightblue rounded-lg text-darkgrey shadow-inner shadow-[inset_5px_5px_10px_rgba(0,0,0,0.15),_inset_-5px_-5px_10px_rgba(255,255,255,0.1)]"
                    />
                  </div>
                ) : (
                  <div className="flex flex-col gap-3 p-3">
                    <label htmlFor={field.id}>{field.label}</label>
                    <input
                      type="text"
                      id={field.id}
                      value={field.prefix ? `${field.prefix} ${formData[field.id]}` : formData[field.id]}
                      disabled
                      className="p-4 text-lightgrey"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="m-4 mt-auto">
          <h3 className="text-lg mb-4">My email Address</h3>
          <div className="flex gap-3 text-darkgrey items-center">
            <div className="p-3 bg-lightblue rounded-full">
              <FaEnvelope size={24} className="text-blue" />
            </div>
            <div className="flex flex-col">
              <span>{email}</span>
              <Link to="/resetpassword" className="underline">change password***</Link>
            </div>
          </div>  
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
