import React, { useState } from "react";
import InvoiceTable from "../../components/InvoiceTable";
import Layout from "../../utils/Layout";
import menu from "../../assets/misc/hamburger.png";

const Invoice = () => {
  const [openDropdown, setDropdown] = useState(false);
  const [filter, setFilter] = useState("TechStack Invoice");

  const filterInvoices = (newFilter) => {
    setFilter(newFilter);
    setDropdown(false);
  };

  const getFilterText = () => {
    switch (filter) {
      case "TechStack Invoice":
        return "TechStack invoices";
      case "Upcoming Bills":
        return " Upcoming Bills";
      case "Client Invoices":
        return " Client specific invoices";
      case "Tools Invoices":
        return " Tools specific invoices";
      default:
        return " All Upcoming Bills";
    }
  };

  return (
    <Layout>
      <div className="p-3 flex justify-between">
        <div>
          <h2 className="text-2xl font-semibold">Invoice</h2>
          <span className="text-gray-600">
            You can check your {getFilterText()} here
          </span>
        </div>
        <div className="flex items-center gap-3 relative">
          <button
            className="bg-white px-2 py-1 "
            onClick={() => setDropdown(!openDropdown)}
          >
            <img src={menu} alt="hamburger menu" />
          </button>

          <button className="bg-white px-2 py-1 text-darkgrey">
            Order History
          </button>
          {openDropdown && (
            <div className="absolute right-full top-full bg-white shadow-md rounded-b-md rounded-l-md">
              <ul className="list-none p-2 w-[200px]">
                {[
                  "TechStack Invoice",
                  "Upcoming Bills",
                  "Client Invoices",
                  "Tools Invoices",
                ].map((option) => (
                  <li
                    key={option}
                    className="py-1 px-2 hover:border hover:border-input-border rounded-lg my-1 cursor-pointer"
                    onClick={() => filterInvoices(option)}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div>
        <InvoiceTable filter={filter}/>
      </div>
    </Layout>
  );
};

export default Invoice;
