import React from 'react';
import { Navigate } from 'react-router-dom';

const SessionAuth = ({ children }) => {
  const accessToken = localStorage.getItem('authToken')

  if (!accessToken) {
    return <Navigate to="/signin" />;
  }
  return children;
};

export default SessionAuth;

// import React, { useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";
// import Session from "supertokens-web-js/recipe/session";
// import { EmailVerificationClaim } from "supertokens-web-js/recipe/emailverification";

// const SessionAuth = ({ children }) => {
//   const [loading, setLoading] = useState(true);
//   const [isVerified, setIsVerified] = useState(false);
//   const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
//   const authToken = localStorage.getItem("authToken");
//   const authType = localStorage.getItem("authType");
//   const isAuth = localStorage.getItem("isAuth");

//   useEffect(() => {
//     const checkSession = async () => {
//       const session = await Session.doesSessionExist();
//       console.log("Session", session);
//       const signInMethod = localStorage.getItem("signinMethod");

//       if (!authToken) {
//         setLoading(false);
//         return;
//       }

//       if (authType === "login") {
//         setLoading(false);
//         return;
//       }

//       setIsGoogleSignIn(signInMethod === "google");

//       if (signInMethod === "google") {
//         setIsVerified(true);
//         setLoading(false);
//         return;
//       }

//       if (await Session.doesSessionExist()) {
//         const validationErrors = await Session.validateClaims();
//         if (validationErrors.length === 0) {
//           setIsVerified(true);
//         } else {
//           const isEmailVerified = validationErrors.some(
//             (err) => err.id === EmailVerificationClaim.id
//           );
//           setIsVerified(!isEmailVerified);
//         }
//       }

//       setLoading(false);
//     };

//     checkSession();
//   }, [authToken, authType]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }
//   if (authToken && !isAuth) {
//     return <Navigate to="/onboard" />;
//   }
//   if (!authToken) {
//     return <Navigate to="/signin" />;
//   }

//   if (authType === "login") {
//     return children;
//   }
//   if (!isGoogleSignIn && !Session.doesSessionExist()) {
//     return <Navigate to="/signin" />;
//   }

//   if (!isVerified && !isGoogleSignIn) {
//     return <Navigate to="/verify-email" />;
//   }

//   return children;
// };

// export default SessionAuth;
