import React from "react";
import { MdErrorOutline } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

const ErrorComponent = ({ error }) => {
  const handleClose = () => {
    window.location.reload();
  };

  return (
    <div className="bg-red p-4 rounded-full my-4 w-full mx-20 xl:mx-32 text-white flex justify-between items-center">
      <div className="flex items-center gap-2">
        <MdErrorOutline size={20} />
        <span>{error}</span>
      </div>
      <button onClick={handleClose} className="focus:outline-none border-l">
        <AiOutlineClose size={20} />
      </button>
    </div>
  );
};

export default ErrorComponent;
