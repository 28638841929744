import React from "react";
import { IoClose } from "react-icons/io5";
import { TiTick } from "react-icons/ti";

const MarketplaceDetailsTable = ({ itemDetails }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full mt-4">
        <thead className="text-sm rounded-full">
          <tr className="bg-blue p-2 text-white rounded-full">
            <th className="px-4 font-light text-left"></th>
            {itemDetails.plan_options.map((option, idx) => (
              <th
                key={idx}
                className="font-light p-2 text-center w-20 border-l-2 border-[#DDDDDD]"
              >
                {option}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {itemDetails.categories.map((featureData, index) => {
            return (
              <>
                {/* Category Title */}
                <tr key={index} className="bg-white rounded-lg">
                  <td className="px-4 font-semibold pt-4 border-[#DDDDDD] border-r-2">
                    {featureData.featureTitle}
                  </td>
                  {itemDetails.plan_options.map((option) => (
                    <td
                      className="border-[#DDDDDD] border-l-2"
                      key={option}
                    ></td>
                  ))}
                </tr>

                {/* Category Features */}
                {featureData.features.map((feature, index) => {
                  return (
                    <tr className="text-sm" key={index}>
                      <td className="px-4">{feature.title}</td>
                      {itemDetails.plan_options.map((option) => (
                        <td
                          className="pl-10 w-24 border-[#DDDDDD] border-l-2"
                          key={option}
                        >
                          {feature.options.includes(option) ? (
                            <TiTick size={24} color="green" />
                          ) : (
                            <IoClose size={24} color="red" />
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </>
            );
          })}

          {/* {itemDetails.categories.map((featureData, index) => (
            <tr key={index} className="bg-white rounded-lg">
              {featureData.features.map((feature, index) => (
                <td className="py-2 px-4" key={index}>
                  {feature.title}
                </td>
              ))}
            </tr>
          ))} */}

          {/* {filteredInvoices.map((invoice, index) => (
        <tr key={index} className="bg-white rounded-lg">
          <td className="py-2 px-4">{invoice.invoiceNo}</td>
          <td className="py-2 px-4">{invoice.services}</td>
          <td className="py-2 px-4">{invoice.billingDate}</td>
          <td className="py-2 px-4">{invoice.plan}</td>
          <td className="py-2 px-4">{invoice.amount}</td>
          <td className="py-2 px-4">
            <button
              className={`px-4 py-2 rounded-full ${
                invoice.status === "Paid"
                  ? "bg-green text-darkgreen "
                  : invoice.status === "Expired"
                  ? "bg-lightyellow text-mustard"
                  : "bg-lightblue text-blue"
              }`}
            >
              {invoice.status}
            </button>
          </td>
        </tr>
      ))} */}
        </tbody>
      </table>
    </div>
  );
};

export default MarketplaceDetailsTable;
