import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove tokens from local storage
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("liveChatOrganizationIdHash");
    localStorage.removeItem("liveChatClientIdHash");
    localStorage.removeItem("isAuth");
    localStorage.removeItem("firstName");
    // Navigate to login page
    navigate("/login");
  };

  return (
    <button
      className="text-sm font-md hover:text-blue-600 p-2"
      onClick={handleLogout}
    >
      Logout
    </button>
  );
};

export default Logout;
