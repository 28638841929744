import React, { useEffect, useState } from "react";

const InvoiceTable = ({ filter }) => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (timestamp) => {
    // Convert the timestamp to milliseconds by multiplying by 1000
    const date = new Date(timestamp * 1000);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the day, month, and year from the date object
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as "DD MMM YYYY"
    return `${day} ${month} ${year}`;
  };

  const fetchInvoices = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `https://paymentmicroservice.onrender.com/get-invoices`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: "vivek6@tech.com" }),
        }
      );

      if (response) {
        const data = await response.json();
        console.log(data);

        setInvoices(data.invoices);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full my-8 border-separate border-spacing-y-3">
        <thead className="text-sm">
          <tr>
            <th className="py-2 px-4 font-light text-left">Invoice No.</th>
            <th className="py-2 px-4 font-light text-left">Billing Date</th>
            <th className="py-2 px-4 font-light text-left">Next Renewal</th>
            <th className="py-2 px-4 font-light text-left">Amount</th>
            <th className="py-2 px-4 font-light text-left">Status</th>
          </tr>
        </thead>

        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            Loading...
          </div>
        ) : (
          <tbody>
            {invoices &&
              invoices.map((invoice, index) => (
                <tr
                  key={index}
                  className="bg-white rounded-lg hover:cursor-pointer hover:bg-[#efefef] hover:text-darkgrey"
                  onClick={() => window.open(invoice.invoice_url)}
                >
                  <td className="py-2 pl-4">{invoice.id}</td>
                  <td className="py-2 px-4">{formatDate(invoice.date)}</td>
                  <td className="py-2 px-4">{invoice.next_payment_date}</td>
                  <td className="py-2 px-4">${invoice.amount}</td>
                  <td className="py-2 px-4">
                    <button
                      className={`px-4 py-2 rounded-full ${
                        invoice.status
                          ? "bg-green text-darkgreen "
                          : "bg-lightyellow text-mustard"
                      }`}
                    >
                      {invoice.status ? "Paid" : "Pending"}
                    </button>
                  </td>
                  {/* <td className="flex justify-center items-end h-[36px] mr-8">
                    <FaDownload
                      className="hover:cursor-pointer"
                      onClick={() => window.open(invoice.invoice_pdf)}
                    />
                  </td> */}
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default InvoiceTable;
